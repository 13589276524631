<template>
  <div>
    <v-alert v-if="(response && (response.success || response.error))" text :type="response.error ? 'error' : 'success'">
      {{ response.success }}
      {{ response.message }}
      <ul v-if="(typeof response.error) === 'object'">
        <li v-for="(item, idx) in response.error" :key="idx">
          {{ item[0] }}
        </li>
      </ul>
    </v-alert>
    <v-alert v-if="(response && response.exception)" text type="error">
      {{ response.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'AlertResponse',
  props: [
    'response'
  ]
}
</script>

<style scoped>

</style>
