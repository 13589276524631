<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Pievienojiet menedžeru</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" lg="3">
              <v-text-field label="Vārds" v-model="form.name" outlined autofocus required :rules="rules"/>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field label="E-pasts" type="email" v-model="form.email" outlined required :rules="rules"/>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field
                v-model="form.password"
                :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                :rules="rules"
                label="Parole"
                outlined
                counter
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <AlertResponse :response="alert"/>
      <v-card-actions>
        <v-btn :disabled="!valid" color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'
import AlertResponse from '@/components/AlertResponse'

export default {
  name: 'UserCreate',
  components: {
    AlertResponse
  },
  data: () => ({
    valid: false,
    rules: [
      value => !!value || 'Nepieciešams'
    ],
    alert: null,
    show1: null,
    form: {
      name: '',
      email: '',
      password: '',
      status: 1
    }
  }),
  methods: {
    save () {
      if (this.valid === true) {
        axios.post('users', this.form).then(response => {
          this.alert = response.data
          this.valid = false
          setTimeout(() => this.$router.push({ name: 'userIndex' }), 2000)
        }).catch(error => {
          this.alert = error.data
          this.valid = true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
